<template>
  <!-- 加载样式 -->
  <!-- <div class="preloader"><div class="spinner"></div></div> -->
   <!-- /.preloader -->
  <!-- 头部 -->
  <Header/>
  <router-view/>
  <!-- 底部标签 -->
  <Footer/>
  <div class="fixed_div">
     <!-- <div class="topbox">
        <center>TOP</center>
      </div> -->
    联系电话：010-8208 9304 
    <br>                                              
    邮&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;箱：mkt@edu-xr.com
</div>
</template>
<script>
import Header from './components/public/header.vue'
import Footer from './components/public/footer.vue'
export default {
  components: {
    Header,
    Footer
  }
}
</script>
<style>
.fixed_div{
  z-index: 1000;
  position:fixed;
  right: 15px;
  bottom: 8%;
  color: #f5f5f5;
  background-color: rgba(0, 0, 0, 0.21);
  padding: 12px;
  border-radius: 5px
}
</style>
