import axios from "axios";
import { ElMessage } from 'element-plus'
axios.defaults.baseURL = 'https://api.cloud.edu-xr.com' //正式
export default function axiosApi(url, method, params, headers ,responseType, type = 0) {
  return new Promise((resolve, reject) => {
    axios({
        method: method,
        url: axios.defaults.baseURL+url,
        headers: headers,
        responseType: responseType,
        data: params
      })
      .then(res => {
        console
        if (type === 1){
          resolve(res.data)
        }else{
          if (res.data.code == 200) {
            resolve(res.data)
          } else {
            if (res.data.code === 410005) {
              ElMessage.error(res.data.msg)
              return false;
            }else{
              ElMessage.error(res.data.msg)
            }
          }
        }
      })
      .catch(err => {
        reject(err)
      });
  })
  
}