<template>
  <div class="header">
    <header class="header header-home-one">
        <nav class="navbar navbar-default header-navigation stricky">
            <div class="thm-container clearfix">
                <!-- Brand and toggle get grouped for better mobile display -->
                <div class="navbar-header">
                    <!-- <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target=".main-navigation" aria-expanded="false"> 
                        <i class="fas fa-bars"></i>
                    </button> -->
                    <a class="navbar-brand" href="/">
                        <img src="img/logo.png" alt="Awesome Image"/>
                    </a>
                </div>
                <div class="collapse navbar-collapse main-navigation mainmenu " id="main-nav-bar">
                    
                    <ul class="nav navbar-nav navigation-box">
                        <li>  
                            <!-- <a href="/">首页</a>  -->
                            <a href="/"></a> 
                        </li>
                        <!-- <li > 
                            <a href="/solution">解决方案</a> 
                            <ul class="sub-menu">
                                <li> <a href="/platform">XR区校/管理云平台</a> </li>
                                <li><a href="/datastatic">可视化交互展示</a></li>
                                <li><a href="/dangjian">XR思政管理云平台</a></li>
                                <li><a href="/creation">VR拍摄与创意制作</a></li>   
                                <li><a href="/changguan">VR场馆制作</a></li> 
                                <li><a href="/experiment">XR+虚拟实验教学</a></li> 
                            </ul>
                        </li>
                        <li> 
                            <a href="/smartedu">5G-VR智慧教育</a> 
                            <ul class="sub-menu right-align">
                                <li><a href="/smartedu-subject">VR+学科教育</a></li>
                                <li><a href="/smartedu-dangjian">VR+党建/思政教育</a></li>
                                <li><a href="/smartedu-safety">VR+安全教育</a></li>
                                <li><a href="/smartedu-science">VR+科普教育</a></li>
                            </ul>
                        </li>
                        <li> <a href="/case">经典案例</a> </li>
                        <li> <a href="/contact">合作支持</a> </li>
                        <li> <a href="/about">关于我们</a> </li> -->
                    </ul>                
                </div>
            </div>
        </nav>   
    </header><!-- /.header -->
  </div>
</template>

<script>

export default {
  name: 'HeaderBox',
  components: {

  },
  data() {
      return {
          
      }
  }
}
</script>
