<template>
  <div class="footer">
    <div class="footer-bottom">
        <el-row style="width:100%;" :gutter="20">
            <el-col :sm="12" :md="12" :lg="12" :xl="12">
                <div style="float:right;">
                    <img src="https://edu-xr-bj.oss-cn-beijing.aliyuncs.com/website/new/erweima/%E4%BA%8C%E7%BB%B4%E7%A0%81.jpg" style="width:120px;heignt:auto;">
                </div>
            </el-col>
            <el-col :sm="12" :md="12" :lg="12" :xl="12">                
                <div style="float:left; font-size:12px;margin-left:5px;">
                    电话：010-8208 9304 <br>
                    邮箱：mkt@edu-xr.com <br>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;
                    baiyu@edu-xr.com<br>
                    地址：北京市海淀区中关村大街18号
                </div>
            </el-col>
        </el-row>
        <br>
        <div class="thm-container text-center">
            <p>&copy;  Copyright © 2017-{{year}}  北京幻育科技有限公司 版权所有<br><a target="_blank" href="https://beian.miit.gov.cn/">京ICP备2021026887号-1</a></p>
        </div><!-- /.thm-container -->    
    </div><!-- /.footer-bottom -->

    <div class="search_area zoom-anim-dialog mfp-hide " id="test-search">
        <div class="search_box_inner">
            <div class="input-group">
                <input type="text" class="form-control" placeholder="Search for...">
                <span class="input-group-btn">
                    <button class="btn btn-default" type="button"><i class="fas fa-search"></i></button>
                </span>
            </div>
        </div>
    </div>
    <div class="scroll-to-top scroll-to-target" data-target="html"><i class="fa fa-angle-up"></i></div> 
  </div>
</template>

<script>

export default {
  name: 'FooterBox',
  components: {

  },
  data () {
      return {
          year: ''
      }
  },
  created () {
      let timeStamp = new Date()
      this.year = new Date(timeStamp).getFullYear();
  }
}
</script>
