<template>
 <center>
  <div class="softBox">
    <el-card class="box-card">
      <el-divider content-position="left">软件下载</el-divider>
      <el-table
        :data="tableData"
        :span-method="objectSpanMethod"
        border
        style="width: 100%; margin-top: 20px">
        <el-table-column
          prop="name"
          label="软件名称">
        </el-table-column>
        <el-table-column
          prop="content"
          label="说明">
        </el-table-column>
        <el-table-column
          label="官网地址">
          <template #default="scope">
          <el-link type="primary" :href="scope.row.auth" target="_blank">{{scope.row.name}}官网地址</el-link>
          </template>
        </el-table-column>
        <el-table-column
          prop="amount1"
          label="下载地址">
          <template #default="scope">
          <el-link type="primary" :href="scope.row.url" target="_blank">{{scope.row.amount1}}</el-link>
          &nbsp; &nbsp;
          <el-link type="primary" :href="scope.row.auth" target="_blank">{{scope.row.mobile}}</el-link>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
  </div>
 </center>
</template>

<script>
import { addNonUsersLog } from '@/api/user_log'
  export default {
    data() {
      return {
        headers: {
          'Content-Type': 'application/json'
        },
        tableData: [{
          id: '12987122',
          name: 'Chrome谷歌浏览器',
          amount1: 'Windows',
          content: '推荐浏览器',
          mobile: '安卓',
          auth: 'https://www.google.cn/intl/zh-CN/chrome/',
          url:'https://www.google.cn/intl/zh-CN/chrome/'
        }, {
          id: '12987123',
          name: 'Chrome谷歌浏览器',
          amount1: 'macOS',
          content: '幻育推荐浏览器',
          mobile: 'ios',
          auth: 'https://www.google.cn/intl/zh-CN/chrome/',
          url:'https://dl.google.com/chrome/mac/universal/stable/GGRO/googlechrome.dmg'
        }, {
          id: '12987124',
          name: 'Insta360 Studio',
          amount1: 'Windows',
          content:'instal360（GO 2、ONE RS/R、X3/X2/X）后期素材处理软件',
          mobile: '安卓',
          auth: 'https://www.insta360.com/cn/download/insta360-x3',
          url:'https://file.insta360.com/static/9973779adc8c5e1f7cd3fa2bcbdf0a53/Insta360%20Studio%202023_Winx64_4.6.5_build_202301131905.exe'
        }, {
          id: '12987125',
          name: 'Insta360 Studio',
          amount1: 'macOS',
          content:'instal360（GO 2、ONE RS/R、X3/X2/X）后期素材处理软件',
          mobile: 'ios',
          auth: 'https://www.insta360.com/cn/download/insta360-x3',
          url:'https://file.insta360.com/static/2e182434c5e4e9723b4e890d680aab39/Insta360%20Studio%202023_20230113_192010_signed.pkg'
        }, {
          id: '12987126',
          name: 'Insta360 Pro2',
          amount1: 'Windows',
          content: 'Insta360 Pro 2 控制软件',
          mobile: '安卓',
          auth: 'https://www.insta360.com/cn/download/insta360-x3',
          url:'https://static.insta360.com/software/Pro/Windows/Insta360Pro_Winx64_2.1.0_build_20181212.exe'
        }, {
          id: '12987125',
          name: 'Insta360 Pro2',
          amount1: 'macOS',
          content: 'Insta360 Pro 2 控制软件',
          mobile: 'ios',
          auth: 'https://www.insta360.com/cn/download/insta360-x3',
          url:'https://static.insta360.com/software/Pro/Mac/Insta360Pro_Mac_2.1.1_build_20190105.pkg'
        }, {
          id: '12987125',
          name: 'Insta360 Stitcher',
          amount1: 'Windows',
          content: 'Insta360 Pro 2后期素材处理软件',
          mobile: '安卓',
          auth: 'https://www.insta360.com/cn/download/insta360-x3',
          url:'https://static.insta360.com/software/Stitcher/Windows/Insta360Stitcher_Winx64_3.0.0_20190622.exe'
        }, {
          id: '12987125',
          name: 'Insta360 Stitcher',
          amount1: 'macOS',
          content: 'Insta360 Pro 2后期素材处理软件。',
          mobile: 'ios',
          auth: 'https://www.insta360.com/cn/download/insta360-x3',
          url:'https://static.insta360.com/software/Stitcher/Mac/Insta360Stitcher_Mac_3.0.0_build_20190817.pkg'
        }, {
          id: '12987128',
          name: '飞书',
          amount1: 'Windows',
          content: '飞书整合即时沟通、日历、音视频会议、云文档、云盘、工作台等功能于一体。',
          mobile: '安卓',
          auth: 'https://www.feishu.cn/',
          url: 'https://www.feishu.cn/'
        }, {
          id: '12987129',
          name: '飞书',
          amount1: 'MacOs',
          content: '飞书整合即时沟通、日历、音视频会议、云文档、云盘、工作台等功能于一体。',
          mobile: 'ios',
          auth: 'https://www.feishu.cn/',
          url: 'https://www.feishu.cn/'
        }, {
          id: '12987130',
          name: '钉钉',
          amount1: 'Windows',
          content: '阿里巴巴集团打造的企业级智能移动办公平台。',
          mobile: '安卓',
          auth: 'https://page.dingtalk.com/wow/z/dingtalk/simple/ddhomedownlaod#/',
          url: 'https://page.dingtalk.com/wow/z/dingtalk/simple/ddhomedownlaod#/'
        }, {
          id: '12987130',
          name: '钉钉',
          amount1: 'MacOs',
          content: '阿里巴巴集团打造的企业级智能移动办公平台。',
          mobile: 'ios',
          auth: 'https://page.dingtalk.com/wow/z/dingtalk/simple/ddhomedownlaod#/',
          url: 'https://page.dingtalk.com/wow/z/dingtalk/simple/ddhomedownlaod#/'
        }, {
          id: '12987131',
          name: 'Foxmail',
          amount1: 'Windows',
          content: 'foxmail官方免费版是一款基于internet规范专业好用的电子邮件客户端管理软件。',
          mobile: '安卓',
          auth: 'https://www.foxmail.com/',
          url: 'https://dldir1.qq.com/foxmail/windows/FoxmailSetup_7.2.25.213.exe'
        }, {
          id: '12987132',
          name: 'Foxmail',
          amount1: 'MacOs',
          content: 'foxmail官方免费版是一款基于internet规范专业好用的电子邮件客户端管理软件。',
          mobile: 'ios',
          auth: 'https://www.foxmail.com/',
          url: 'https://dldir1.qq.com/foxmail/MacFoxmail/Foxmail_for_Mac_1.5.8.94580.dmg'
        }
        ]
      };
    },
    mounted () {
      this.addNonUsersLog()
    },
    methods: {
      addNonUsersLog(){
        var parms = {
          name:'访问官网',
          object:'访问官网软件下载页'
        }
        addNonUsersLog(this.headers,parms)
      },
      objectSpanMethod({ row, column, rowIndex, columnIndex }) {
        if (columnIndex <=2) {
          if (rowIndex % 2 === 0) {
            return {
              rowspan: 2,
              colspan: 1
            };
          } else {
            return {
              rowspan: 0,
              colspan: 0
            };
          }
        }
      }
    }
  };
</script>
<style scoped>
  .softBox{
    width: 1323px;
    margin-top: 100px;
    margin-bottom: 100px;
  }
</style>