<template>

<section class="features-style-one  bg-style-one padding-two">
    <div class="thm-container">
        <div class="title">
            <h3>政策背景</h3>
        </div>
        <el-row :gutter="20">
            <el-col :sm="24" :md="24" :lg="12" :xl="12">
                <img style="width:100%; margin-top:8px;" src="//edu-xr-bj.oss-cn-beijing.aliyuncs.com/website/new/zhnegce/37691662380252_.pic.jpg" alt="" />
            </el-col>
            <el-col :sm="24" :md="24" :lg="12" :xl="12">
                    <el-link :underline="false" href="http://www.moe.gov.cn/jyb_xxgk/moe_1777/moe_1778/201701/t20170119_295319.html" target="_blank">
                    <h4 style="color:#47558e;">国务院关于印发国家教育事业发展“十三五规划”的通知</h4></el-link>
                    <p> 
                    全力推动信息技术与教育教学深度融合。……鼓励学校利用大数据技术开展对教育教学活动和学生行为数据的收集、分析和反馈，为推动个性化学习和针对性教学提供支持。 支持各级各类学校建设智慧校园，综合利用 <span class="zhongdian">互联网、大数据</span>、人工智能和<span class="zhongdian">虚拟现实</span>技术探索未来教育教学新模式。
                    </p>
            </el-col>
        </el-row>
        <el-row :gutter="20">
            <el-col :sm="24" :md="24" :lg="12" :xl="12">
                <img style="width:100%; margin-top:18px;" src="//edu-xr-bj.oss-cn-beijing.aliyuncs.com/website/new/zhnegce/37671662379857_.pic.jpg" alt="" /></el-col>
            <el-col :sm="24" :md="24" :lg="12" :xl="12">
                    <el-link :underline="false" href="http://www.moe.gov.cn/srcsite/A10/s7034/201803/t20180323_331063.html" target="_blank">
                    <h4 style="color:#47558e;">教育部等五部门关于印发《教师教育振兴行动计划（2018—2022年）》的通知</h4></el-link>
                    <p>（五） “互联网+教师教育”创新行动。 充分利用<span  class="zhongdian">云计算、大数据、虚拟现实</span>、人工智能等新技术，推进教师教育信息化教学服务<span  class="zhongdian">平台建设和应用</span>，推动以自主、合作、探究为主要特征的教学方式变革。……实施新一周期中小学<span  class="zhongdian">教师信息技术应用能力提升</span>工程，引领带动中小学教师校长将现代信息技术有效运用于教育教学和学校管理。……加强在职教师培训信息化管理，建设教师专业发展“学分银行”。</p>
            </el-col>
        </el-row>
        <el-row :gutter="20">
            <el-col :sm="24" :md="24" :lg="12" :xl="12">
                <img style="width:100%; margin-top:18px;" src="//edu-xr-bj.oss-cn-beijing.aliyuncs.com/website/new/zhnegce/37681662379857_.pic.jpg" alt="" />
            </el-col>
            <el-col :sm="24" :md="24" :lg="12" :xl="12">
                    <el-link :underline="false" href="http://www.moe.gov.cn/srcsite/A06/s3321/201911/t20191128_409958.html" target="_blank">
                    <h4 style="color:#47558e;">教育部关于加强和改进中小学实验教学的意见</h4></el-link>
                    <p>
                       2.创新实验教学方式。……对于因受时空限制而在现实世界中无法观察和控制的事物和现象、变化太快或太慢的过程，以及有危险性、破坏性和对环境有危害的实验，可用增强现实、<span  class="zhongdian">虚拟现实</span>等技术手段呈现。 
                    </p>
                    <p>
                        6.健全实验教学评价机制。把实验教学情况纳入教育质量评价监测体系， …… 把教师实验教学能力、教学水平和教学实绩作为相关学科教师职称评聘、绩效奖励等的重要依据。 ……把学生实验操作情况和能力表现纳入综合素质评价； 2023年前要 <span  class="zhongdian">将实验操作纳入初中学业水平考试</span>,考试成绩纳入高中阶段学校招生录取依据；在普通高中学业水平考试中，有条件的地区可将理化生实验操作纳入省级统一考试。
                    </p>
            </el-col>
        </el-row>
    </div><!-- /.thm-container -->
</section><!-- 政策 -->

<section class="features-style-one bg-style-two padding-two">
    <div class="thm-container">
        <div class="title">
            <h3>八大应用方向</h3>
        </div>
        <!-- <el-row :gutter="20">
            <el-col :sm="24" :md="24" :lg="24" :xl="24"> -->
                <iframe src="xuanzhaun.html" v-if="xuanzhaun" loading="lazy" frameborder="0" width="100%" height="850px"></iframe>
            <!-- </el-col> -->
            <!-- <el-col :sm="24" :md="24" :lg="10" :xl="10">
                <br><br><br>
                    <h3 style="color:#191c28;font-size: 20px;"><b>.</b> VR学科教学</h3> 
                    <h3 style="color:#191c28;font-size: 20px;"><b>.</b> VR党建/红色教育</h3> 
                    <h3 style="color:#191c28;font-size: 20px;"><b>.</b> VR安全教育</h3> 
                    <h3 style="color:#191c28;font-size: 20px;"><b>.</b> VR科普学习</h3> 
                    <h3 style="color:#191c28;font-size: 20px;"><b>.</b> VR导览、文化、历史展示</h3> 
                    <h3 style="color:#191c28;font-size: 20px;"><b>.</b> VR创客教学</h3> 
                    <h3 style="color:#191c28;font-size: 20px;"><b>.</b> VR录播（赛事、课堂、室内外活动、会议）</h3> 
                    <h3 style="color:#191c28;font-size: 20px;"><b>.</b> VR知识分享（游学）</h3> 
            </el-col> -->
        <!-- </el-row> -->
    </div>
</section><!-- 场景 -->

<section class="features-style-one bg-style-one">
    <div class="thm-container">
        <div class="title">
            <h3>为什么选择幻育</h3>
        </div> 
        <div class="row">
            <div class="col-md-3 col-sm-6 col-xs-12">
                <div class="single-feature-style-one text-left">
                    <div class="icon-box hvr-radial-out text-center">
                        <i class="binmp-icon-human-resources"></i>
                    </div>
                    <div class="text-box text-left" style="margin-right:30px;">
                        <h3>成熟的产品及方案</h3>
                        <p>XR管理云平台是集硬件、软件、资源、云服务于一体化的XR教育解决方案，全部采用行业优秀的技术方案与硬件产品，资源与国家课程标准匹配，方案已经过市场的检验。</p>
                    </div>
                </div>
            </div>
            <div class="col-md-3 col-sm-6 col-xs-12">
                <div class="single-feature-style-one text-left">
                    <div class="icon-box hvr-radial-out text-center">
                        <i class="binmp-icon-setting"></i>
                    </div>
                    <div class="text-box" style="margin-right:30px;">
                        <h3>顶级的设计与研发</h3>
                        <p>公司产品研发团队均为具有10年教育行业经验与开发经验的优秀成员，具有丰富的教育信息化实施与应用经验，充分了解市场与客户需求、前端技术发展趋势。</p>
                    </div>
                </div>
            </div>
            <div class="col-md-3 col-sm-6 col-xs-12">
                <div class="single-feature-style-one text-left">
                    <div class="icon-box hvr-radial-out text-center">
                        <i class="binmp-icon-options"></i>
                    </div>
                    <div class="text-box text-left" style="margin-right:30px;">
                        <h3>全面的服务与支持</h3>
                        <p>公司为客户和合作伙伴提供全方位的售前、售中、售后、培训、运维服务，并具备为客户和合作伙伴提供丰富的平台与资源定制化开发的服务能力。</p>
                    </div>
                </div>
            </div><!-- /.col-md-4 -->
            <div class="col-md-3 col-sm-6 col-xs-12">
                <div class="single-feature-style-one text-left">
                    <div class="icon-box hvr-radial-out text-center">
                        <i class="binmp-icon-exploration"></i>
                    </div><!-- /.icon-box -->
                    <div class="text-box text-left" style="margin-right:30px;">
                        <h3>覆盖全国的业务支撑</h3>
                        <p>公司在全国设置16个省市区设置了服务网点与办事处，可提供覆盖全国范围的业务支撑，具备快速的属地化响应能力。</p>
                    </div><!-- /.text-box -->
                </div><!-- /.single-feature-style-one -->
            </div><!-- /.col-md-4 -->
        </div><!-- /.row -->
    </div><!-- /.thm-container -->
</section><!-- 为什么选择幻育 -->

<section class=" app-screenshot-style-one bg-style-two">
    <div class="thm-container">
        <div class="title">
            <h3>企业资质</h3>
        </div><!-- /.title -->    
        <Vue3Marquee :pauseOnHover="true" :duration="55">
            <div class="card" v-for="avatar in zizhiArray" :key="avatar">
                 <img :src="avatar" style="widht:300px; height:250px; margin-left:10px;"/>            
            </div>
        </Vue3Marquee>    
    </div><!-- /.thm-container -->
</section><!-- 企业资质 -->

<section class="app-screenshot-style-one ">
    <div class="thm-container">
        <div class="title">
            <h3>部分案例</h3>
        </div><!-- /.title -->  
        <Vue3Marquee :pauseOnHover="true" :duration="55">
            <div class="card" v-for="avatar in avatarArray" :key="avatar">
                 <img :src="avatar" style="widht:300px; height:250px; margin-left:10px;"/>            
            </div>
        </Vue3Marquee>
    </div><!-- /.thm-container -->
</section><!-- 案例 -->

</template>

<script>
import { Vue3Marquee } from 'vue3-marquee'
import 'vue3-marquee/dist/style.css'
export default {
  name: 'FeaturesStyleOne',
   components: {
        Vue3Marquee,
  },
  setup() {
    return {
        zizhiArray:[
            '//edu-xr-bj.oss-cn-beijing.aliyuncs.com/website/new/zizhi/XR%E4%BA%91%E7%BC%96%E8%BE%91%E7%B3%BB%E7%BB%9Fweb.jpg?x-oss-process=image/quality,q_40',
            '//edu-xr-bj.oss-cn-beijing.aliyuncs.com/website/new/zizhi/XR%E5%8C%BA%E5%9F%9F%E4%BA%91%E5%B9%B3%E5%8F%B0web.jpg?x-oss-process=image/quality,q_40',
            '//edu-xr-bj.oss-cn-beijing.aliyuncs.com/website/new/zizhi/XR%E6%95%B0%E6%8D%AE%E5%8F%AF%E8%A7%86%E5%8C%96%E7%B3%BB%E7%BB%9Fweb.jpg?x-oss-process=image/quality,q_40',
            '//edu-xr-bj.oss-cn-beijing.aliyuncs.com/website/new/zizhi/XR%E7%AE%A1%E7%90%86%E4%BA%91%E5%B9%B3%E5%8F%B0web.jpg?x-oss-process=image/quality,q_40',
            '//edu-xr-bj.oss-cn-beijing.aliyuncs.com/website/new/zizhi/XR%E7%AE%A1%E7%90%86%E4%BA%91%E5%B9%B3%E5%8F%B0%E6%B5%8B%E8%AF%95%E6%8A%A5%E5%91%8Aweb.jpg?x-oss-process=image/quality,q_40',
            '//edu-xr-bj.oss-cn-beijing.aliyuncs.com/website/new/zizhi/XR%E8%AE%BE%E5%A4%87%E7%B3%BB%E7%BB%9Fweb.jpg?x-oss-process=image/quality,q_40',
            '//edu-xr-bj.oss-cn-beijing.aliyuncs.com/website/new/zizhi/XR%E8%AE%BE%E5%A4%87%E7%B3%BB%E7%BB%9F%E6%B5%8B%E8%AF%95%E6%8A%A5%E5%91%8Aweb.jpg?x-oss-process=image/quality,q_40',
            '//edu-xr-bj.oss-cn-beijing.aliyuncs.com/website/new/zizhi/XR%E8%B5%84%E6%BA%90%E7%B3%BB%E7%BB%9Fweb.jpg?x-oss-process=image/quality,q_40',
            '//edu-xr-bj.oss-cn-beijing.aliyuncs.com/website/new/zizhi/%E4%B8%AD%E5%85%B3%E6%9D%91%E9%AB%98%E6%96%B0%E8%AF%81%E4%B9%A6web.jpg?x-oss-process=image/quality,q_40'
        ],
        avatarArray:[
            "//edu-xr-bj.oss-cn-beijing.aliyuncs.com/website/new/anli/%E4%B8%AD%E5%85%B3%E6%9D%91%E4%BA%8C%E5%B0%8F.jpg?x-oss-process=image/quality,q_40",
            "//edu-xr-bj.oss-cn-beijing.aliyuncs.com/website/new/anli/%E5%8C%97%E4%BA%AC%E5%AE%8F%E5%BF%97%E4%B8%AD%E5%AD%A6.jpg?x-oss-process=image/quality,q_40",
            "//edu-xr-bj.oss-cn-beijing.aliyuncs.com/website/new/anli/%E5%8D%97%E4%BA%AC%E5%8F%A4%E6%9F%8F%E5%B0%8F%E5%AD%A6.jpg?x-oss-process=image/quality,q_40",
            "//edu-xr-bj.oss-cn-beijing.aliyuncs.com/website/new/anli/%E5%AF%86%E4%BA%91%E9%9D%92%E5%B0%91%E5%B9%B4%E5%AE%AB.jpg?x-oss-process=image/quality,q_40",
            "//edu-xr-bj.oss-cn-beijing.aliyuncs.com/website/new/anli/%E5%BC%A0%E5%AE%B6%E5%8F%A3%E4%B8%87%E5%85%A8%E5%9B%9B%E5%B0%8F.jpg?x-oss-process=image/quality,q_40",
            "//edu-xr-bj.oss-cn-beijing.aliyuncs.com/website/new/anli/%E6%96%B0%E7%96%86%E5%85%8B%E5%B7%9E%E6%98%86%E4%BB%91%E4%BD%B3%E8%8B%91%E5%B0%8F%E5%AD%A6.jpg?x-oss-process=image/quality,q_40",
            "//edu-xr-bj.oss-cn-beijing.aliyuncs.com/website/new/anli/%E6%96%B0%E7%96%86%E5%85%8B%E5%B7%9E%E9%98%BF%E5%85%8B%E9%99%B6%E5%AE%9E%E9%AA%8C%E5%B0%8F%E5%AD%A6.jpg?x-oss-process=image/quality,q_40",
            "//edu-xr-bj.oss-cn-beijing.aliyuncs.com/website/new/anli/%E6%96%B0%E7%96%86%E8%81%8C%E4%B8%9A%E5%A4%A7%E5%AD%A6%E9%A9%AC%E5%85%8B%E6%80%9D%E4%B8%BB%E4%B9%89%E5%AD%A6%E9%99%A2.jpg?x-oss-process=image/quality,q_40",
            "//edu-xr-bj.oss-cn-beijing.aliyuncs.com/website/new/anli/%E6%B5%B7%E5%8D%97%E7%9C%81%E4%B8%9C%E6%96%B9%E5%B8%82%E7%90%BC%E8%A5%BF%E4%B8%AD%E5%AD%A6.jpg?x-oss-process=image/quality,q_40",
            "//edu-xr-bj.oss-cn-beijing.aliyuncs.com/website/new/anli/%E6%B5%B7%E6%B7%80%E5%8C%BA%E6%95%99%E7%A7%91%E9%99%A2%E6%9C%AA%E6%9D%A5%E5%AE%9E%E9%AA%8C%E5%B0%8F%E5%AD%A6.jpg?x-oss-process=image/quality,q_40",
            "//edu-xr-bj.oss-cn-beijing.aliyuncs.com/website/new/anli/%E9%9D%99%E6%95%99%E9%99%A2%E9%99%84%E6%A0%A1.jpg?x-oss-process=image/quality,q_40",
            "//edu-xr-bj.oss-cn-beijing.aliyuncs.com/website/new/anli/%E9%B9%A4%E5%A3%81%E5%B8%82%E5%A4%96%E5%9B%BD%E8%AF%AD%E4%B8%AD%E5%AD%A6.jpg?x-oss-process=image/quality,q_40"
       ]
    }
  },
  data () {
      return {
       xuanzhaun: false
      }
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll () { // 实现当滚动到指定位置，触发动画
      let scrollTop =  window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop; // 获取窗口
      if (scrollTop >= 1700) {
        this.xuanzhaun = true
      }else{
        this.xuanzhaun = false
      }
    },
  },
  created () {

  }
}
</script>
<style>
    h3 {
        line-height: 30px;
        letter-spacing: 1px;
    }
    h4 {
        line-height: 30px;
        letter-spacing: 1px;
    }
    .zhongdian {
        font-weight: 500;
        color:rgb(236 50 50);
    }
    span {
        letter-spacing: 1px;
    }
    p {
        letter-spacing: 1px;
    }
</style>
