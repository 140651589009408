<template>
  <div class="home">
    <!--Main Slider-->
    <Slider/>
<!--End Main Slider-->

<!-- /.features-style-one -->
    <FeaturesStyleOne/>

  </div>
</template>

<script>
import Slider from '../components/home/slider'
import FeaturesStyleOne from '../components/home/features-style-one'
import { addNonUsersLog } from '@/api/user_log'
export default {
  headers: {
    'Content-Type': 'application/json'
  },
  name: 'HomeView',
  components: {
    Slider,
    FeaturesStyleOne
  },
  mounted () {
    this.addNonUsersLog()
  },
  methods: {
    addNonUsersLog(){
      var parms = {
        name:'访问官网',
        object:'访问官网首页'
      }
      addNonUsersLog(this.headers,parms)
    }
  }
}
</script>
